import React from "react";
import {
  FaBandcamp,
  FaHome,
  FaServicestack,
  FaCertificate,
} from "react-icons/fa";

export const QUICK_LINKS = [
  {
    url: "/",
    q_icon: <FaHome />,
    name: "Home",
  },
  {
    url: "/about/",
    q_icon: <FaBandcamp />,
    name: "About Us",
  },
  {
    url: "/services/",
    q_icon: <FaServicestack />,
    name: "Services",
  },
  {
    url: "/certifications/",
    q_icon: <FaCertificate />,
    name: "Certifications",
  },
  {
    url: "/knowledge-center/",
    q_icon: <FaBandcamp />,
    name: "Knowledge Center",
  },
  {
    url: "/work-with-us/",
    q_icon: <FaBandcamp />,
    name: "Work With Us",
  },
  {
    url: "/portals/",
    q_icon: <FaBandcamp />,
    name: "Portals",
  },
  {
    url: "/contacts/",
    q_icon: <FaBandcamp />,
    name: "Contact Us",
  },
];


export const FQUICK_LINKS = [
  {
    url: "/about/",
    q_icon: <FaBandcamp />,
    name: "About Us",
  },
  {
    url: "https://nema.go.ke/",
    q_icon: <FaBandcamp />,
    name: "NEMA",
  },
  {
    url: "https://www.labour.go.ke/occupational-safety-and-health-services",
    q_icon: <FaBandcamp />,
    name: "DOSHS",
  },
  {
    url: "/contacts/",
    q_icon: <FaBandcamp />,
    name: "Contact Us",
  },
];

