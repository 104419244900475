import React, { useContext } from "react";
import { StaticImage } from "gatsby-plugin-image";
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "../../context/GlobalContextProvider";
import { Link } from "gatsby";
import { FaBandcamp, FaHome, FaServicestack } from "react-icons/fa";
import "./navigation.css";

import Navbar from "../navigationv3/Navigation";
function Logo() {
  return (
    <div className="logo">
      <Link to="/">
        <StaticImage
          src="../../images/logo.png"
          alt="logo"
          loading="eager"
          objectFit="cover"
        />
      </Link>
    </div>
  );
}
function NavigationLinks() {
  return (
    <div className="nav-links mobile-active">
      <Navbar />
      <div className="contact service-nav-links">
        <Link to="/contacts" className="contact-us-btn-nav">
          <p>Contact Us</p>
        </Link>
      </div>
    </div>
  );
}
function SideMenus({ menuOpen, handleMenu }) {
  const menuItems = [
    {
      icon: <FaHome />,
      label: "HOME",
      link: "/",
    },
    {
      icon: <FaBandcamp />,
      label: "ABOUT US",
      link: "/about/",
    },
    // Add more menu items as needed
  ];

  const sideMenuStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    width:  "100%" ,
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    color: "var(--text-secondary)",
    background: "var(--background-secondary)",
    margin: 0,
    padding: 0,
    overflow: "hidden",
    zIndex: 100,
    opacity: 1,
    transition: "all 0.5s ease-in-out",
  };

  const linksContainerStyle = {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    margin: "var(--navbar) auto 0",
  };

  const linkStyle = {
    textDecoration: "none",
    color: "#000", // black text color
    margin: "10px 0",
    padding: "10px 20px", // Add some padding for better touch experience
    fontSize: "20px",
    textAlign: "center",
    borderRadius: "5px", // Rounded corners
    display:"flex",
    justifyContent: "center",

  };

  const iconStyle = {
    margin: "10px",
    fontSize: "24px",
  };

  return (
    <div style={sideMenuStyle}>
      <div style={linksContainerStyle}>
        {menuItems.map((item, index) => (
          <div key={index} style={linkStyle}>
            <div style={iconStyle}>{item.icon}</div>
            <Link onClick={handleMenu} to={item.link} style={linkStyle}>
              {item.label}
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
}

function SideMenu({ menuOpen, handleMenu }) {
  return (
    <div className="side-menu">
      <div className="side-menu-links-container">
        <div className="side-menu-links-icon-ul">
          <div className="side-menu-links-icon">
            <FaHome />
          </div>
          <Link onClick={handleMenu} to="/" className="side-menu-links-li">
            HOME
          </Link>
        </div>
        <div className="side-menu-links-icon-ul">
          <div className="side-menu-links-icon">
            <FaBandcamp />
          </div>
          <Link
            onClick={handleMenu}
            to="/about/"
            className="side-menu-links-li"
          >
            ABOUT US
          </Link>
        </div>{" "}
        <div className="side-menu-links-icon-ul">
          <div className="side-menu-links-icon">
            <FaServicestack />
          </div>
          <Link
            onClick={handleMenu}
            to="/services/"
            className="side-menu-links-li"
          >
            SERVICES
          </Link>
        </div>{" "}
        <div className="side-menu-links-icon-ul">
          <div className="side-menu-links-icon">
            <FaBandcamp />
          </div>
          <Link
            onClick={handleMenu}
            to="/knowledge/"
            className="side-menu-links-li"
          >
            KNOWLEDGE CENTER
          </Link>
        </div>
        <div className="side-menu-links-icon-ul">
          <div className="side-menu-links-icon">
            <FaBandcamp />
          </div>
          <Link
            onClick={handleMenu}
            to="/work-with-us/"
            className="side-menu-links-li"
          >
            WORK WITH US
          </Link>
        </div>
        <div className="side-menu-links-icon-ul">
          <div className="side-menu-links-icon">
            <FaBandcamp />
          </div>
          <Link onClick={handleMenu} to="/" className="side-menu-links-li">
            PORTALS
          </Link>
        </div>
        <div className="side-menu-links-icon-ul">
          <div className="side-menu-links-icon">
            <FaBandcamp />
          </div>
          <Link
            onClick={handleMenu}
            to="/contacts/"
            className="side-menu-links-li"
          >
            CONTACTS
          </Link>
        </div>
      </div>
    </div>
  );
}
function MenuIconOpen({ handleMenu }) {
  return (
    <div className="menu-icon" onClick={handleMenu}>
      <div className="menu-icon-one menu-bar"></div>
      <div className="menu-icon-two menu-bar"></div>
      <div className="menu-icon-three menu-bar"></div>
    </div>
  );
}
function MenuIconClose({ handleMenu }) {
  return (
    <div className="menu-icon menu-close" onClick={handleMenu}>
      <div className="menu-bar menu-icon-one-close"></div>
      <div className="menu-bar menu-icon-two-close"></div>
    </div>
  );
}

function Navigation() {
  const dispatch = useContext(GlobalDispatchContext);
  const state = useContext(GlobalStateContext);

  const [menuopen, setMenuOpen] = React.useState(true);

  const handlemenu = (e) => {
    setMenuOpen((prev) => !prev);
    dispatch({ type: "TOGGLE_MENU" });
  };

  return (
    <>
      <div className="navbar-container">
        <Logo />
        <div className="flex-grow"></div>
        <NavigationLinks />
        {menuopen ? (
          <MenuIconOpen handleMenu={handlemenu} />
        ) : (
          <MenuIconClose handleMenu={handlemenu} />
        )}
      </div>
      {!menuopen ? (
        <SideMenu menuOpen={menuopen} handleMenu={handlemenu} />
      ) : null}
    </>
  );
}

export default Navigation;
