import React from "react";
import { GoLocation } from "react-icons/go";
import { AiOutlineMail, AiFillPhone } from "react-icons/ai";
import { FaRegAddressBook } from "react-icons/fa";

export const CONTACTS = [
  {
    c_icon: <GoLocation />,
    name: "Buruburu Arcade",
  },
  {
    c_icon: <FaRegAddressBook />,
    name: "8987-00200, Nairobi",
  },
  {
    c_icon: <AiOutlineMail />,
    name: "Nexton@nexton-ehs.com",
  },
  {
    c_icon: <AiFillPhone />,
    name: "+254 722 150 664",
  },
];
