import React, { useContext } from "react";
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "../../context/GlobalContextProvider";
import useWindowSize from "../../hooks/useWindowSize";
import Footer from "../footer/footer";
import Navigation from "../navigation/navigation";
import "./layout.css";
export default function Layout({ children }) {
  const state = useContext(GlobalStateContext);
  const dispatch = useContext(GlobalDispatchContext);

  const { width } = useWindowSize();
  let menu;
  let display;
  if (state.menu === "close" || width > 1040) {
    menu = false;
    display = "flex";
  } else {
    menu = true;
    display = "none";
  }

  const layoutchildren = {
    display: menu ? "none" : "flex",
    width: "100%",
    height: "auto",
    minHeight: "100vh",
    margin: "50px auto 0",
  };
  const navigationlayout = {
    width: "100%",
    height: "auto",
    display: "flex",
    flexDirection: "column",
    height:'auto',
  };
  const footerlayout = {
    width: "100%",
    height: "auto",
    display: menu ? "none" : "flex",
    flexDirection: "column",
    backgroundColor: "var(--black)",
  };
  return (
    <div className="layout">
      <div style={navigationlayout}>
        <Navigation />
      </div>
      <div className="layout-children" style={layoutchildren}>
        {children}
      </div>
      <div style={footerlayout}>
        {" "}
        <Footer />
      </div>
    </div>
  );
}
