import React from "react";
import { Link } from "gatsby";
import { FaBandcamp } from "react-icons/fa";
import "./footer.css";
import { FQUICK_LINKS } from "../../data/quick_links";
import { SOCIAL_LINKS } from "../../data/social_links";
import { CONTACTS } from "../../data/contacts";
import { FTRSERVICES } from "../../data/fservices";
import FooterDesigner from "./footer_designer";
import Wrapper from "../wrapper/wrapper";

const FooterContacts = ({ data }) => (
  <div className="footer-sub-container-content-icon-text">
    <div className="footer-sub-container-content-icon">{data.c_icon}</div>
    <div className="footer-sub-container-content-text">{data.name}</div>
  </div>
);

const FooterService = ({ data }) => (
  <div className="footer-sub-container-content-icon-text">
    <div className="footer-sub-container-content-icon">{data.s_icon}</div>
    <Link to={data.s_url} className="footer-sub-container-content-text">
      {data.name}
    </Link>
  </div>
);

const FooterQuicklinks = ({ data }) => (
  <div className="footer-sub-container-content-icon-text">
    <div className="footer-sub-container-content-icon">{data.q_icon}</div>
    <a
      href={data.url}
      className="footer-sub-container-content-text"
      target="_blank"
      rel="noopener noreferrer"
    >
      {data.name}
    </a>
  </div>
);

const FooterSocialMedia = ({ data }) => (
  <div className="footer-sub-container-content-icon-text">
    <div className="footer-sub-container-content-icon">{data.s_icon}</div>
    <a
      href={data.url}
      className="footer-sub-container-content-text"
      target="_blank"
      rel="noopener noreferrer"
    >
      {data.name}
    </a>
  </div>
);

function Footer() {
  const FServices = (
    <>
      {FTRSERVICES.map((fservice, index) => (
        <FooterService key={index} data={fservice} />
      ))}
    </>
  );
  const FQuicklinks = (
    <>
      {FQUICK_LINKS.map((fqlink, index) => (
        <FooterQuicklinks key={index} data={fqlink} />
      ))}
    </>
  );
  const FContacts = (
    <>
      {CONTACTS.map((fcontact, index) => (
        <FooterContacts key={index} data={fcontact} />
      ))}
    </>
  );
  const FSocialMedia = (
    <>
      {SOCIAL_LINKS.map((fsocialm, index) => (
        <FooterSocialMedia key={index} data={fsocialm} />
      ))}
    </>
  );

  return (
    <>
      <footer className="footer">
        <Wrapper>
          <div className="footer-ehs-description">
            <p>
              Nexton Limited is an Industrial / Environmental Laboratory,
              Designated by N.E.M.A, Having Satisfied all requirements under ISO
              17025 in order to address the Growing need for Effective
              Environmental Measurements and analysis of Air, Water, Noise and
              Ground Vibration in accordance with the current Environmental
              Regulations. Additionally, we are Consultants in the Field of
              Occupational Safety and Health and Industrial Training.
            </p>
          </div>
          <div className="footer-container">
            <div className="footer-sub-container quick-links">
              <div className="footer-sub-container-header">QUICK LINKS</div>
              <div className="footer-sub-container-content">{FQuicklinks}</div>
            </div>
            <div className="footer-sub-container services">
              <div className="footer-sub-container-header">SERVICES</div>
              <div className="footer-sub-container-content">{FServices}</div>
            </div>
            <div className="footer-sub-container social-media">
              <div className="footer-sub-container-header">SOCIAL MEDIA</div>
              <div className="footer-sub-container-content">{FSocialMedia}</div>
            </div>
            <div className="footer-sub-container contact-us">
              <div className="footer-sub-container-header">CONTACT US</div>
              <div className="footer-sub-container-content">{FContacts}</div>
            </div>
          </div>
        </Wrapper>
      </footer>
      <FooterDesigner />
    </>
  );
}

export default Footer;
