import React from 'react'
import styles from './wrapper.css'
const Wrapper = ({
    children
}) => {
    return (
        <div className='width_wrapper'>{children}</div>
    )
}

export default Wrapper