export const menuItems = [
  {
    title: "home",
    url: "/",
  },
  {
    title: "about_us",
    url: "/about",
  },
  {
    title: "Services",
    url: "/services",
    submenu: [
      {
        title: "Air quality Testing",
        submenu: [
          {
            title: "Stack Emission Testing",
            url: "/services/stack-emission-testing",
          }, {
            title: "Ambient air Monitoring",
            url: "/services/ambient-air-monitoring",
          },
          {
            title: "Landfill Gas Analysis",
            url: "/services/landfill-gas-analysis",
          },

        {
            title: "Air Dispersion modelling",
            url: "/services/air-dispersion-modelling",
          },
          {
            title: "Fume Exposure Analysis",
            url: "/services/fume-exposure-analysis",
          },
          {
            title: "Compressed Air Testing",
            url: "/services/compressed-air-testing",
          },
          {
            title: "Indoor Air Quality Monitoring",
            url: "/services/indoor-air-quality-monitoring",
          },
          
        //   {
        //     title: "Backend",
        //     submenu: [
        //       {
        //         title: "NodeJS",
        //         url: "/node",
        //       },
        //       {
        //         title: "PHP",
        //         url: "/php",
        //       },
        //     ],
        //   },
        ],
      },
      {
        title: "HVAC Validation",
        url: "/services/hvac-validation",
      },
      {
        title: "Audit & Assessments",
        url: "/services/performance-audit",
      }, 
     {
        title: "Laboratory Services",
        url: "/services/laboratory-services",
      }, {
        title: "Calibration services",
        url: "/services/calibration-services",
      }, {
        title: "Industrial Training",
        url: "/services/industry-training",
      },     {
        title: "noise and ground vibration survey",
        url: "/services/noise-and-ground-vibration-survey",
      },  {
        title: "NDT Testing on Boilers & Steam Pressure vessels ",
        url: "/services/non-destructive-testing",
      },
    ],
  },
  {
    title: "Knowledge Center",
    url: "/knowledge",
    // submenu: [
    //   {
    //     title: "Who we are",
    //     url: "/who-we-are",
    //   },
    //   {
    //     title: "Our values",
    //     url: "/our-values",
    //   },
    // ],

  },
  {
    title: "work with us",
    submenu: [
      {
        title: "Partner With Us",
        url: "/work-with-us/",
      },
      {
        title: "Vacancies",
        url: "/work-with-us/",
      },

      {
        title: "Public Advertisements",
        url: "/work-with-us/",
      }
    ],
  },
  
  {
    title: "portals",
    url: "/",
  },
];
