import React from "react";
import {
  FaFacebook,
  FaLinkedin,
  FaTwitter,
  FaInstagram,
} from "react-icons/fa";

export const SOCIAL_LINKS = [
  {
    s_icon: <FaFacebook />,
    name: "facebook",
    url: "https://web.facebook.com/NextonLtd",
  },
  {
    s_icon: <FaTwitter />,
    name: "twitter",
    url: "https://twitter.com/NextonLtd",
  },
  {
    s_icon: <FaLinkedin />,
    name: "linkedin",
    url: "http://linkedin.com/company/nextonltd",
  },
  {
    s_icon: <FaInstagram />,
    name: "Instagram",
    url: "https://www.instagram.com/nextonlimited/",
  },
];
