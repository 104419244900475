import React from 'react'

function FooterDesigner() {
  const copyright = {
    display:'flex',
    width:'100%',
    height:'40px',
    backgroundColor:'grey',
    flexDirection:'column',
    justifyContent: 'flex-start',
    padding:'0 20px 0 10px',
    color:'#FFFFFF',
  }
  const copyrightmessage = {
    fontSize:'.6rem',
    color:'#FFFFFF',
    margin: '.6rem auto 0 auto',

  }


  return (
    <div style={copyright}>
      {/* <span style={copyrightmessage}>Copyright 2023 | Nexton LTD | All Rights Reserverd:---Developed by:ModernaCyber</span>       */}
      <span style={copyrightmessage}>Copyright Reserved to  Nexton LTD  2023</span>      
    </div>
  )
}

export default FooterDesigner