import React from "react";
import {  FaServicestack } from "react-icons/fa";

export const FTSERVICES = [
  {
    s_icon: <FaServicestack />,
    sub_services:[

    ],
    s_url: "/services/stack-emission-testing/",
    name: "Stack Emission Testing", 

  },
  {
    s_icon: <FaServicestack />,
    sub_services:[

    ],
    s_url: "/services/indoor-air-quality-monitoring/",
    name: "Indoor Air Quality", 

  },
  {
    s_icon: <FaServicestack />,
    sub_services:[

    ],
    s_url: "/services/fume-exposure-analysis/",
    name: "Fume Exposure Analysis", 

  },
  {
    s_icon: <FaServicestack />,
    sub_services:[

    ],
    s_url: "/services/compressed-air-testing/",
    name: "Compressed Air Testing", 

  },
  {
    s_icon: <FaServicestack />,
    sub_services:[

    ],
    s_url: "/services/landfill-gas-analysis/",
    name: "Landfill Gas Analysis", 

  },
  {
    s_icon: <FaServicestack />,
    sub_services:[

    ],
    s_url: "/services/ambient-air-monitoring/",
    name: "Ambient Air Monitoring Services",
  },  {
    s_icon: <FaServicestack />,
    sub_services:[

    ],
    s_url: "/services/noise-and-ground-vibration-survey/",
    name: "Noise and Ground Vibration Survey",
  },  {
    s_icon: <FaServicestack/>,
    sub_services:[

    ],
    s_url: "/services/air-dispersion-modelling/",
    name: "Air Dispersion Modelling Services",
  },  {
    s_icon: <FaServicestack />,
    sub_services:[

    ],
    s_url: "/services/hvac-validation/",
    name: "HVAC validation",
  },
  {
    s_icon: <FaServicestack />,
    sub_services:[

    ],
    s_url: "/services/performance-audit/",
    name: "Performance audit",
  },
  {
    s_icon: <FaServicestack />,
    sub_services:[

    ],
    s_url: "/services/laboratory-services/",
    name: "Laboratory services",
  },
  {
    s_icon: <FaServicestack />,
    sub_services:[

    ],
    s_url: "/services/calibration-services/",
    name: "Calibration services",
  },
  {
    s_icon: <FaServicestack />,
    sub_services:[

    ],
    s_url: "/services/industry-training/",
    name: "Industry training",
  }, {
    s_icon: <FaServicestack />,
    sub_services:[

    ],
    s_url: "/services/non-destructive-testing/",
    name: "Non destructive testing",
  },
];

export const FTRSERVICES = [
  {
    s_icon: <FaServicestack />,
    sub_services:[

    ],
    s_url: "/services/laboratory-services/",
    name: "Laboratory Services",
  },
  {
    s_icon: <FaServicestack />,
    sub_services:[

    ],
    s_url: "/services/calibration-services/",
    name: "Calibration services",
  },
  {
    s_icon: <FaServicestack />,
    sub_services:[

    ],
    s_url: "/services/industry-training/",
    name: "Industry training",
  },
  {
    s_icon: <FaServicestack />,
    sub_services:[

    ],
    s_url: "/services/",
    name: "more ...",
  },
];
